import { zhCN } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export * from 'utils/PageConfig/catalog';
export * from 'utils/PageConfig/myAccount';

export const Primary = '#090909';
export const Secondary = '#808080';
export const Disabled = '#CECECE';
export const LightGreyBG = '#F8F9F9';
export const White = '#FFFFFF';
export const Transparent = 'transparent';
export const Black = '#000';

export const FitLineBrandColorRed = '#CD0039';
export const FitLineBrandColorRed20 = '#F5CCD7';
export const FitLineBrandColorRedInteraction = '#B80033';

export const FitLineBrandColorPeach = '#FFF3E0';
export const FitLineBrandColorPeachSupport = '#FFFAF3';
export const FitLineBrandColorPeach20 = 'RGBA(255, 243, 224, 0.2)';
export const FitLineBrandColorDarkPeach = '#F6D7A4';
export const FitLineButtonClickBackgroundColor = '#A4002D';
export const FitLineColorTestingDisabled = '#F2F2F7';
export const FitLineBrandColorSilver = '#A7A9AC';

export const Success = '#4CAF50';
export const Warning = '#BE1800';
export const WarningBG = '#FFE7E9';
export const Pending = '#E67E22';
export const Wechat = '#1AAD19';

export const SearchBgColor = '#FFF3E0';
export const SearchPlaceHolderColor = '#808080';
export const SearchHeight = 56;
export const transitionTime = 0.5;
export const safeAreaInsetBottom = 'env(safe-area-inset-bottom)';
export const compatibleBottomBarHeight = (h = 72) => `calc(${h}px + ${safeAreaInsetBottom})`;

export const BlogMenuListBorderColor = '#5DC7E9';

const breakpoints = createBreakpoints({});

const theme = createTheme(
  {
    // breakpoints: {
    //   values: {
    //     xs: 0,
    //     sm: 480,
    //     md: 640,
    //     lg: 960,
    //     xl: 1920,
    //   },
    // },
    palette: {
      type: 'light',
      primary: {
        main: FitLineBrandColorRed,
        white: White,
        disabled: Disabled,
        pending: Pending,
        transparent: Transparent,
        black: Black,
        peach: FitLineBrandColorPeach,
        silver: FitLineBrandColorSilver,
        darkPeach: FitLineBrandColorDarkPeach,
        secondary: Secondary,
        pinkMain: FitLineBrandColorRed20,
        menuBottom: BlogMenuListBorderColor,
        placeholder: SearchPlaceHolderColor,
        success: Success,
      },
      secondary: {
        main: FitLineBrandColorRedInteraction,
        peach: FitLineBrandColorPeachSupport,
      },
      text: {
        primary: Primary,
        secondary: Secondary,
        disabled: Disabled,
        main: FitLineBrandColorRed,
      },
      warning: {
        main: Warning,
        bg: WarningBG,
      },
      error: {
        main: Warning,
      },
      success: {
        main: Success,
      },
      disabled: {
        background: LightGreyBG,
      },
      default: {
        main: Secondary,
      },
      background: {
        primary: FitLineBrandColorRed,
        default: White,
        peach: FitLineBrandColorPeach,
        white: White,
        darkPeach: FitLineBrandColorDarkPeach,
        peachSupport: FitLineBrandColorPeachSupport,
        disabled: LightGreyBG,
        secondary: Secondary,
        success: Success,
        testingDisabled: FitLineColorTestingDisabled,
        transparent: Transparent,
      },
      divider: LightGreyBG,
      orderStatus: {
        pendingPayment: Warning,
        pending_payment: Warning,
        fraud: Warning,
        pendingPacking: Pending,
        pending_packing: Pending,
        pendingShipment: Pending,
        pending_shipment: Pending,
        inTransit: Pending,
        in_transit: Pending,
        onHold: Warning,
        on_hold: Warning,
        holded: FitLineBrandColorRedInteraction,
        complete: Success,
        reviewed: Success,
        canceled: Disabled,
        closed: Disabled,
      },
      socialMedia: {
        wechat: Wechat,
        weixin: Wechat,
      },
      color: {
        peach: FitLineBrandColorDarkPeach,
      },
    },
    shadows: ['none', ...Array(24).fill('0 0 16px rgba(0,0,0,0.08)')],
    typography: {
      fontFamily: ['Helvetica Neue LT Pro', '华文细黑', '黑体'].join(','),
      // example for H1 based StyleGuide
      h1: {
        fontSize: '1.5rem',
        lineHeight: 1.5,
        fontWeight: 700,
        fontFamily: '"Clan Pro"',
        // letterSpacing: '-0.09rem',
        [breakpoints.up('md')]: {
          fontFamily: '"Clan Pro"',
          fontSize: '2rem',
          lineHeight: 1.375,
          fontWeight: 700,
          // letterSpacing: '-0.11rem',
        },
      },
      h2: {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 700,
        // letterSpacing: '-0.06rem',
        fontFamily: '"Clan Pro"',
        [breakpoints.up('md')]: {
          fontFamily: '"Clan Pro"',
          fontSize: '1.5rem',
          lineHeight: 1.5,
          fontWeight: 700,
          // letterSpacing: '-0.11rem',
        },
      },
      h3: {
        fontSize: '0.875rem',
        lineHeight: 1.428,
        fontWeight: 500,
        // letterSpacing: '-0.06rem',
        [breakpoints.up('md')]: {
          fontFamily: 'Clan Pro',
          fontSize: '1rem',
          lineHeight: 1.5,
          fontWeight: 700,
          letterSpacing: 0,
        },
      },
      h4: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
        fontWeight: 500,
        // letterSpacing: '-0.04rem',
        [breakpoints.up('md')]: {
          fontSize: '0.875rem',
          lineHeight: 1.429,
          // letterSpacing: '-0.06rem',
        },
      },
      h5: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
        fontWeight: 400,
        // letterSpacing: '-0.06rem',
        [breakpoints.up('md')]: {
          fontSize: '0.75rem',
          lineHeight: 1.5,
          fontWeight: 400,
          // letterSpacing: '-0.05rem',
        },
      },
      h6: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
        fontWeight: 400,
        // letterSpacing: '-0.04rem',
        [breakpoints.up('md')]: {
          fontSize: '0.75rem',
          fontWeight: 500,
          lineHeight: 1.428,
          // letterSpacing: '-0.04rem',
        },
      },
      subtitle1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 500,
        [breakpoints.up('md')]: {
          fontSize: '1rem',
          fontWeight: 550,
          lineHeight: 1.5,
        },
      },
      subtitle2: {
        fontSize: '1rem',
        lineHeight: 1.4,
        fontWeight: 400,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 400,
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
        fontWeight: 400,
      },
      caption: {
        fontSize: '0.625rem',
        lineHeight: 1.4,
        fontWeight: 400,
        // letterSpacing: '-0.04rem',
        [breakpoints.up('md')]: {
          fontSize: '0.75rem',
          lineHeight: 1.4,
          fontWeight: 400,
          // letterSpacing: '-0.04rem',
        },
      },
      button: {
        fontSize: '0.875rem',
        lineHeight: 1.42,
        fontWeight: 700,
        // letterSpacing: '-0.05rem',
        [breakpoints.up('md')]: {
          fontSize: '0.875rem',
          lineHeight: 1.4,
          fontWeight: 700,
          // letterSpacing: '-0.05rem',
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
        appBarHeight: 64,
        compatibleHeight: compatibleBottomBarHeight,
        safeAreaInsetBottom,
      },
      transitionTime,
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
            {
              border: ' none !important',
              '-webkit-text-fill-color': 'inherit !important',
              '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },
          'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
            {
              '-webkit-appearance': 'none',
              margin: 0,
            },
          '*': {
            'scroll-behavior': 'smooth',
          },
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          /* Firefox */
          'input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          'div .MuiInputBase-root button': {
            padding: '0px !important',
          },
          '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          'li, ul': {
            listStyle: 'none',
          },
        },
      },
      MuiTablePagination: {
        toolbar: {
          display: 'flex',
          flexFlow: 'wrap',
        },
      },
      MuiChip: {
        root: {
          color: White,
          backgroundColor: FitLineBrandColorSilver,
          borderRadius: 4,
          fontWeight: 500,
          fontSize: '0.875rem',
          [breakpoints.up('md')]: {
            backgroundColor: Secondary,
          },
        },
        sizeSmall: {
          height: 'auto',
          padding: '0 4px',
          fontSize: '0.75rem',
          lineHeight: 1.5,
        },
        outlined: {
          color: Secondary,
          borderColor: FitLineBrandColorPeach,
          [breakpoints.up('md')]: {
            borderColor: FitLineBrandColorDarkPeach,
          },
        },
        outlinedPrimary: {
          color: Primary,

          '&$sizeSmall': {
            color: FitLineBrandColorRed,
          },
        },
        labelSmall: {
          padding: '0 !important',
        },
      },
      MuiDivider: {
        root: {
          border: 'none',
          height: 1,
          // margin: 'auto',
          // width: '100%',
          backgroundColor: LightGreyBG,
        },
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: '#58e0c7',
          },
          '&$active': {
            color: '#00ad96',
          },
        },
        text: {
          fontWeight: 500,
          fill: 'white',
        },
      },
      MuiBadge: {
        dot: {
          height: 10,
          minWidth: 10,
        },
      },
      MuiCheckbox: {
        root: {
          // minWidth: '20px !important',
          // width: 20,
          // padding: '0 !important',
          '& .MuiIconButton-root': {
            padding: '0 !important',
          },
          '& .MuiIconButton-label': {
            color: White,
          },
          '& svg': {
            width: 20,
            height: 20,
          },
          '&:hover': {
            '& .MuiIconButton-label': {
              color: FitLineBrandColorPeach,
            },
          },
        },
      },
      MuiTypography: {
        paragraph: {
          marginBottom: 8,
          [breakpoints.up('md')]: {
            marginBottom: 16,
          },
        },
        gutterBottom: {
          marginBottom: 8,
          [breakpoints.up('md')]: {
            marginBottom: 16,
          },
        },
      },
      MuiDialog: {
        paper: {
          margin: 16,
        },
        paperFullWidth: {
          width: 'calc(100% - 32px)',
        },
      },
      MuiButton: {
        // An example to set the styles by following the StyleGuide(contained/outlined button only)
        root: {
          textAlign: 'center',
          fontSize: '0.875rem',
          minWidth: 48,
          minHeight: 48,
          boxShadow: 'none !important',
          whiteSpace: 'nowrap',
          fontWeight: 700,
          textTransform: 'initial',
          '&$disabled': {
            color: Disabled,
          },
          [breakpoints.up('md')]: {
            fontWeight: 550,
            lineHeight: 1.43,
          },
        },
        disabled: {},
        textSizeSmall: {
          fontSize: '0.75rem',
          minHeight: 'auto',
        },
        containedSizeLarge: {
          height: 48,
          padding: '8px 24px',
          fontSize: '0.875rem',
        },
        contained: {
          borderRadius: 24,
          paddingLeft: 24,
          paddingRight: 24,
          height: 48,
          fontFamily: 'Helvetica Neue',
          // fix the issue for mobile devices on long press
          // '&:hover': {
          //   backgroundColor: Secondary,
          // },
          '&$disabled': {
            backgroundColor: Disabled,
            color: White,
          },
          // '@media (hover: none)': {
          //   '&:hover': {
          //     backgroundColor: `${FitLineBrandColorRedInteraction} !important`,
          //   },
          // },
        },
        outlined: {
          paddingLeft: 24,
          paddingRight: 24,
          borderRadius: 24,
          height: 48,
          backgroundColor: White,
          '&$disabled': {
            borderColor: FitLineBrandColorPeachSupport,
          },
          // fix the issue for mobile devices on long press
          // '@media (hover: none)': {
          //   '&:hover': {
          //     backgroundColor: `${FitLineBrandColorRedInteraction} !important`,
          //     color: FitLineBrandColorRedInteraction,
          //     borderColor: FitLineBrandColorRedInteraction,
          //   },
          // },
        },
        text: {
          paddingLeft: 0,
          paddingRight: 0,
          '& .MuiDivider-root': {
            width: '100%',
            height: 2,
            backgroundColor: FitLineBrandColorRed,
          },
          '&.Mui-disabled': {
            color: Disabled,
            '& .MuiTypography-root': {
              color: Disabled,
            },
          },
          [breakpoints.up('md')]: {
            '&:hover': {
              background: 'none',
              color: FitLineBrandColorRed,
              '& svg': {
                color: FitLineBrandColorRed,
                transition: `all ${transitionTime}s`,
              },
              '& .MuiTypography-root': {
                color: `${FitLineBrandColorRed} !important`,
                transition: `all ${transitionTime}s`,
              },
            },
            '&.iconButton': {
              minWidth: 32,
              minHeight: 32,
              borderRadius: '50%',
              '&:hover': {
                color: FitLineBrandColorRed,
                background: FitLineBrandColorPeach,
                '@media (hover: none)': {
                  background: 'none',
                },
                '& svg': {
                  color: FitLineBrandColorRed,
                },
              },
            },
            '&.iconButton.MuiButton-textSizeLarge': {
              padding: '0 !important',
            },
            '&.MuiButton-textPrimary.hover': {
              '& .MuiDivider-root': { width: 0 },
              '&:hover': {
                '& .MuiDivider-root': {
                  width: '100%',
                  transition: 'all 1s',
                },
                '& .MuiTypography-root': {
                  color: FitLineBrandColorRed,
                  transition: `all ${transitionTime}s`,
                },
              },
            },
            '&.MuiButton-textPrimary:hover': {
              background: 'none',
            },
          },
        },
        textPrimary: {
          [breakpoints.up('md')]: {
            '&:hover': {
              color: FitLineBrandColorRedInteraction,
            },
          },
        },
        // textSizeMedium: {},
        containedPrimary: {
          color: White,
          [breakpoints.up('md')]: {
            '&:hover': {
              backgroundColor: FitLineBrandColorRedInteraction,
            },
          },
          '&.active': {
            backgroundColor: FitLineButtonClickBackgroundColor,
          },
        },
        // containedSecondary: {
        //   color: White,
        //   borderColor: Secondary,
        //   boxShadow: 'none',
        //   backgroundColor: Secondary,
        //   '&:hover': {
        //     color: White,
        //     borderColor: Secondary,
        //     boxShadow: 'none',
        //     backgroundColor: Secondary,
        //   },
        // },
        outlinedPrimary: {
          borderColor: FitLineBrandColorRed,
          '@media (any-hover:hover)': {
            '&:hover': {
              backgroundColor: FitLineBrandColorRedInteraction,
              borderColor: FitLineBrandColorRedInteraction,
              color: White,
              '& .MuiSvgIcon-colorPrimary': {
                color: White,
              },
            },
          },
          '&.active': {
            backgroundColor: FitLineButtonClickBackgroundColor,
            color: White,
            '& .MuiSvgIcon-colorPrimary': {
              color: White,
            },
          },
        },
        // outlinedSecondary: {
        //   color: Warning,
        //   borderColor: Warning,
        //   backgroundColor: White,
        //   '&:hover': {
        //     color: Warning,
        //     borderColor: Warning,
        //     backgroundColor: White,
        //   },
        // },
        startIcon: {
          '&.MuiButton-iconSizeSmall': {
            marginLeft: 0,
          },
        },
        endIcon: {
          '&.MuiButton-iconSizeSmall': {
            marginRight: 0,
          },
        },
        iconSizeSmall: {
          '& > *:first-child': {
            fontSize: '0.75rem',
          },
        },
        iconSizeMedium: {
          marginLeft: '4px',
          '& > *:first-child': {
            fontSize: '1rem',
          },
        },
        iconSizeLarge: {
          '& > *:first-child': {
            fontSize: '1.5rem',
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:not(.MuiSwitch-switchBase)': {
            // width: 48,
            // height: 48,
            '@media (any-hover:hover)': {
              '&:hover': {
                // color: FitLineBrandColorRedInteraction,
                color: 'inherit',
                backgroundColor: FitLineBrandColorPeach,
              },
            },
          },
        },
      },
      MuiLinearProgress: {
        barColorSecondary: {
          backgroundColor: Warning,
        },
      },
      MuiBottomNavigationAction: {
        wrapper: {
          display: 'flex',
          justifyContent: 'center',
          height: '45px',
          alignSelf: 'center',
        },
        root: {
          '&.Mui-selected': {
            color: FitLineBrandColorRed,
            position: 'relative',
            '&:before': {
              content: '""',
              display: 'table',
              backgroundColor: `${FitLineBrandColorRed}`,
              position: 'absolute',
              left: '50%',
              width: 36,
              height: 2,
              top: 0,
              marginLeft: -18,
            },
          },
        },
        label: {
          '&.Mui-selected': {
            fontWeight: 700,
            fontSize: '0.75rem',
          },
        },
      },
      MuiTextField: {
        root: {
          width: '100%',
          '& .Mui-disabled': {
            overflow: 'hidden',
            background: FitLineBrandColorPeachSupport,
            '& .MuiOutlinedInput-notchedOutline': {
              color: Primary,
              borderColor: FitLineBrandColorPeachSupport,
            },
          },
        },
      },
      MuiInputBase: {
        input: {
          fontSize: '0.875rem',
          color: Primary,
          height: 20,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
          backgroundColor: FitLineBrandColorPeach,
          transition: 'all 0.3s',
          '&:hover': {
            [breakpoints.up('md')]: {
              backgroundColor: FitLineBrandColorDarkPeach,
            },
            '& $notchedOutline': {
              borderColor: FitLineBrandColorDarkPeach,
            },
            '&$focused': {
              backgroundColor: White,
            },
          },
          '&$focused': {
            borderColor: FitLineBrandColorDarkPeach,
            backgroundColor: 'transparent',
            '& $notchedOutline': {
              borderColor: FitLineBrandColorDarkPeach,
            },
          },
          '&.Mui-disabled': {
            overflow: 'hidden',
            background: FitLineBrandColorPeachSupport,
            '& .MuiOutlinedInput-notchedOutline': {
              color: Primary,
              borderColor: FitLineBrandColorPeachSupport,
            },
          },
          '&.Mui-disabled:hover': {
            backgroundColor: FitLineBrandColorPeachSupport,
            '& $notchedOutline': {
              borderColor: FitLineBrandColorPeachSupport,
            },
          },
          '& .MuiOutlinedInput-input.MuiOutlinedInput-inputAdornedStart': {
            padding: '18px 5px',
          },
          '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: '1rem !important',
          },
          '& .MuiInputAdornment-positionEnd': {
            marginRight: '1rem',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          '&$error': {
            color: Warning,
            borderWidth: 1,
            '& $notchedOutline': {
              borderColor: `${Warning} !important`,
              borderWidth: 1,
            },
            '& .MuiOutlinedInput-input': {
              color: Warning,
              '&::placeholder': {
                color: Secondary,
                opacity: 1,
              },
            },
          },
          '@media (hover: none)': {
            '&:hover': {
              '& $notchedOutline': {
                borderColor: FitLineBrandColorPeach,
              },
            },
          },
        },
        input: {
          padding: '18px 16px',
        },
        inputAdornedStart: {
          paddingLeft: 20,
        },
        focused: {},
        error: {},
        multiline: {
          '&$marginDense': {
            padding: 16,
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
        marginDense: {},
        notchedOutline: {
          borderColor: FitLineBrandColorPeach,
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: Secondary,
          },
        },
        focused: {},
      },
      MuiInputLabel: {
        filled: {
          color: Secondary,
          fontSize: '0.875rem',
          transform: 'translate(16px, 20px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, 10px) scale(0.75)',
          },
        },
      },
      MuiFilledInput: {
        root: {
          borderRadius: 8,
          border: `2px solid ${FitLineBrandColorPeach}`,
          backgroundColor: FitLineBrandColorPeach,
          transition: `all ${transitionTime}s`,
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: `${FitLineBrandColorPeach} !important`,
            },
          },
          '&:hover': {
            [breakpoints.up('md')]: {
              backgroundColor: FitLineBrandColorDarkPeach,
              borderColor: FitLineBrandColorDarkPeach,
            },
            '& $notchedOutline': {
              borderColor: FitLineBrandColorDarkPeach,
            },
            '&$focused': {
              backgroundColor: `${White} !important`,
            },
          },
          '&$focused': {
            borderColor: FitLineBrandColorDarkPeach,
            backgroundColor: 'transparent',
            '& $notchedOutline': {
              borderColor: FitLineBrandColorDarkPeach,
            },
          },
          '&.Mui-disabled': {
            overflow: 'hidden',
            background: `${FitLineBrandColorPeachSupport} !important`,
            borderColor: FitLineBrandColorPeachSupport,
            '& .MuiOutlinedInput-notchedOutline': {
              color: Primary,
              borderColor: FitLineBrandColorPeachSupport,
            },
          },
          '&.Mui-disabled:hover': {
            backgroundColor: FitLineBrandColorPeachSupport,
            '& $notchedOutline': {
              borderColor: FitLineBrandColorPeachSupport,
            },
          },
          '&$error': {
            color: Warning,
            borderWidth: 1,
            borderColor: Warning,
            '& $notchedOutline': {
              borderColor: `${Warning} !important`,
              borderWidth: 1,
            },
            '& .MuiFilledInput-input': {
              color: Warning,
              '&::placeholder': {
                color: Warning,
                opacity: 1,
              },
            },
          },
          [breakpoints.up('md')]: {
            '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
              {
                marginTop: 0,
              },
          },
        },
        input: {
          padding: '25px 14px 8px',
          [breakpoints.up('md')]: {
            padding: '18px 16px',
          },
        },
        focused: {},
        error: {},
      },
      MuiTabs: {
        root: {
          minHeight: 48,
          borderBottom: `1px solid ${Secondary}`,
        },
        indicator: {
          backgroundColor: FitLineBrandColorRed,
        },
      },
      MuiTab: {
        root: {
          minHeight: '44px',
          fontWeight: 400,
          textTransform: 'none',
          fontSize: '0.875rem',
        },
        textColorPrimary: {
          '&.Mui-selected': {
            color: FitLineBrandColorRed,
          },
        },
      },
      MuiToolbar: {
        root: {
          minHeight: 50,
        },
      },
      MuiList: {
        root: {
          marginTop: -1,
        },
      },
      MuiListItem: {
        root: {
          paddingTop: 13,
          paddingBottom: 13,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 45,
        },
      },
      MuiTableCell: {
        root: {
          padding: '16px 4px',
          borderBottom: '1px solid #ECECEC',
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: '1rem',
        },
        colorSecondary: {
          color: White,
        },
        fontSizeSmall: { fontSize: '0.75rem' },
        fontSizeLarge: { fontSize: '1.5rem' },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',

          [breakpoints.down('sm')]: {
            '& .MuiToolbar-root': {
              paddingBottom: `calc(8px + ${safeAreaInsetBottom})`,
            },

            '& .MuiToolbar-regular': {
              minHeight: 'auto',
              bottom: 0,
            },
          },
        },
        colorDefault: {
          backgroundColor: White,
        },
      },
      MuiSelect: {
        root: {
          color: Primary,
          '&$select': {
            // backgroundColor: 'red',
          },
        },
        select: {},
      },
      MuiPaper: {
        root: {
          boxShadow: '0 0 16px rgba(0, 0,0,0.08)',
        },
        rounded: {
          // borderRadius: 12,
          [breakpoints.down('sm')]: {
            borderRadius: 12,
          },
        },
      },
      MuiPaginationItem: {
        page: {
          '&$disabled': { color: Disabled, opacity: 1 },
        },
        disabled: {},
      },
    },

    props: {
      MuiSvgIcon: {},
    },
  },
  zhCN,
);

export default theme;
