import { useQuery, useMutation } from 'react-query';
import { PAYMENT_METHODS } from 'containers/CheckoutPage/constants';
import useGetVendor from 'utils/Hooks/useGetVendor';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

const filterPaymentMethods = (methods = [], isHui = false) => {
  let paymentMethods =
    methods?.filter(method => !['cashondelivery', 'banktransfer', 'free'].includes(method.code)) ||
    [];
  paymentMethods = isHui
    ? paymentMethods
    : paymentMethods.filter(method => !method.code.includes('huifu'));

  if (determineEnvironment()) {
    return paymentMethods.filter(
      method =>
        ![PAYMENT_METHODS.H_ALI, PAYMENT_METHODS.ALI, PAYMENT_METHODS.H_UNION].includes(
          method.code,
        ),
    );
  }

  return paymentMethods;
};

export const getOrder = orderId => request('GET', `/customer/orders/${orderId}`);
export const cancelOrder = orderId => request('POST', `/order/${orderId}/cancel`);
export const getShipments = orderId => request('GET', `/customer/orders/${orderId}/shipments`);
export const getPaymentMethods = orderId => request('GET', `/order/${orderId}/payment-methods`);
export const getTrackingInfo = (logisticCode, trackingNumber, phone) =>
  request('GET', `/kuaidi/track/${logisticCode}/${trackingNumber}?phone=${phone}`);
export const reorder = payload => request('POST', '/customer/orders/reorder', payload);
export const behalfUseCredit = orderId =>
  request('POST', `/order-on-behalf/${orderId}/use-creddit`);
export const getPickupQrCode = (orderId, payload) =>
  request('GET', `/pick_up/${orderId}/qrcode`, payload);
export const updateBehalfOrder = data =>
  request('POST', '/order-on-behalf/update-order-status', data);
export const getPaymentMethodsByQuoteId = quoteId =>
  request('GET', `/quote/${quoteId}/payment-methods`);

export const useGetOrder = (id, options = {}) =>
  useQuery(['orderPage.order', id], () => getOrder(id), {
    ...querySetting,
    enabled: !!id,
    ...options,
  });

export const useGetShipments = id =>
  useQuery(id && ['orderPage.shipments', id], () => getShipments(id), querySetting);

export const useGetPaymentMethods = id => {
  const vender = useGetVendor();
  const { data, ...other } = useQuery(
    id && ['orderPage.paymentMethods', id],
    () => getPaymentMethods(id),
    querySetting,
  );

  const paymentMethods = filterPaymentMethods(data, vender?.extension_attributes?.huifu_status);

  return {
    data: paymentMethods,
    ...other,
  };
};
export const useGetPaymentMethodsByQuoiteId = id => {
  const vender = useGetVendor();
  const { data, ...other } = useQuery(
    ['orderPage.getPaymentMethodsByQuoteId', id],
    () => getPaymentMethodsByQuoteId(id),
    {
      ...querySetting,
      enabled: !!id,
    },
  );

  const paymentMethods = filterPaymentMethods(data, vender?.extension_attributes?.huifu_status);

  return {
    data: paymentMethods,
    ...other,
  };
};

export const useGetTrackingInfo = ({ logisticCode, trackingNumber, phone }) =>
  useQuery(
    logisticCode && trackingNumber && ['orderPage.trackingInfo', logisticCode + trackingNumber],
    () => getTrackingInfo(logisticCode, trackingNumber, phone).then(res => JSON.parse(res)),
    querySetting,
  );

export const useGetTrackingInfoMutaion = () =>
  useMutation(({ logisticCode, trackingNumber, phone }) =>
    getTrackingInfo(logisticCode, trackingNumber, phone).then(res => JSON.parse(res)),
  );
export const useReorder = () => useMutation(payload => reorder(payload));
export const useCancelOrder = () => useMutation(id => cancelOrder(id));
// export const useRemoveMineCoupons = () => useMutation(() => removeMineCoupons());
export const useGetPickupQrCodeQuery = orderId =>
  useQuery(['orderPage.getPickupQrCode', orderId], () => getPickupQrCode(orderId), {
    ...querySetting,
    enabled: !!orderId,
  });
export const useBehalfUseCredit = () => useMutation(orderId => behalfUseCredit(orderId));
export const useUpdateBehalfOrder = () => useMutation(payload => updateBehalfOrder(payload));
