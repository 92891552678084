// import { WC_ENV_MP } from 'containers/WeChatProvider/constants';
/* eslint-disable */
/**
 * Determine if current environment is WeChat MiniProgram
 *
 * @returns {bool}  True if the cross-env type as well as the WeChat environment return miniprogram.
 *                  WeChat environment is obtained via the WeChat JSSDK (see index.mp.html).
 */

function determineEnvironment() {
  return navigator.userAgent.toLowerCase().includes('miniprogram') || window.__wxjs_environment === 'miniprogram';
};

export const determineWeChatEnvironment = determineEnvironment()

export default determineEnvironment;
