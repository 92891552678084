import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as Sentry from '@sentry/browser';
import { FormattedMessage, injectIntl } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import appMessages from 'containers/App/messages';
import EmptyState from 'components/EmptyState/Loadable';
import Buttons from 'components/Buttons/Buttons';
import routes from 'utils/routes';
import { BellIcon, AngleLeftIcon16 } from 'components/Icons';
import theme, { FitLineBrandColorRed } from 'muiTheme';
import { selectRouterLocation } from 'containers/App/slices';

const AppWrapper = styled(Box)({
  maxWidth: 'calc(578px + 16px * 2)',
  margin: '0 auto',
  display: 'flex',
  minHeight: 'calc(100vh - var(--vh-offset, 0px))',
  overflowX: 'hidden',
  flexDirection: 'column',
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    backgroundColor: props =>
      props.color === 'primary' ? FitLineBrandColorRed : 'hsl(0, 0%, 100%)',
    minWidth: '1280px',
    maxWidth: '1920px',
    overflowX: 'initial',
    minHeight: 'calc(100vh - var(--vh-offset, 0px))',
    padding: 0,
  },
});

const HomeButton = styled(Buttons)({
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(-6.25),
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
      isDev: process.env.PROJECT_ENV === 'development',
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (prevProps.router.pathname !== router.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    if (!this.state.isDev) {
      const { router, queryClient } = this.props;
      const user = queryClient.getQueryData('userProvider.customer');
      if (user) {
        const { account_id: id, email, mobile: phone, firstname, lastname } = user || {};
        const username = `${lastname}${firstname}`;
        const openid = localStorage.getItem('openid');
        Sentry.configureScope(scope => {
          scope.setUser({ id, email, phone, username, ...(openid ? { openid } : null) });
        });
      }
      Sentry.withScope(scope => {
        // scope.setUser({ id, email });
        scope.setTag('customer.type', 'user');
        scope.setTag('page', router.pathname);
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  showFeedbackForm = () => {
    const { intl } = this.props;
    Sentry.showReportDialog({
      eventId: this.state.eventId,
      title: intl.formatMessage({ id: 'sentry.title' }),
      subtitle: intl.formatMessage({ id: 'sentry.subtitle' }),
      subtitle2: intl.formatMessage({ id: 'sentry.subtitle2' }),
      labelName: intl.formatMessage({ id: 'sentry.labelName' }),
      labelEmail: intl.formatMessage({ id: 'sentry.labelEmail' }),
      labelComments: intl.formatMessage({ id: 'sentry.labelComments' }),
      labelClose: intl.formatMessage({ id: 'sentry.labelClose' }),
      labelSubmit: intl.formatMessage({ id: 'sentry.labelSubmit' }),
      errorGeneric: intl.formatMessage({ id: 'sentry.errorGeneric' }),
      errorFormEntry: intl.formatMessage({ id: 'sentry.errorFormEntry' }),
      successMessage: intl.formatMessage({ id: 'sentry.successMessage' }),
    });
  };

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return !this.state.isDev ? (
        <AppWrapper>
          <CssBaseline />

          <EmptyState
            icon={
              <Box color="text.secondary">
                <SvgIcon component={BellIcon} viewBox="0 0 12 12" style={{ fontSize: '8rem' }} />
              </Box>
            }
            body={
              <Typography variant="body1" color="textSecondary" component="span">
                <FormattedMessage {...appMessages.ourTeamHasBeenNotified} />
              </Typography>
            }
            action={{
              label: <FormattedMessage {...appMessages.reportFeedback} />,
              event: this.showFeedbackForm,
            }}
          />

          <HomeButton
            color="primary"
            fullWidth
            route={routes.home}
            messageId={<FormattedMessage {...appMessages.home} />}
            startIcon={<SvgIcon component={AngleLeftIcon16} viewBox="0 0 16 16" />}
          />
        </AppWrapper>
      ) : (
        <Typography variant="h1">{this.state.error}</Typography>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  queryClient: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  router: selectRouterLocation,
});

const withConnect = connect(mapStateToProps);

export default compose(injectIntl, withConnect)(ErrorBoundary);
