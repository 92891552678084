import { useEffect, useState } from 'react';
import qs from 'qs';

const useGetWechatLoginParams = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    function handleHashChange() {
      try {
        const [values] = Object.values(qs.parse(window.location.hash.substring(1)));
        setParams(JSON.parse(values));
      } catch (err) {
        console.log(err);
      }
    }
    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return params || {};
};

export default useGetWechatLoginParams;
