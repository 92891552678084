/*
 * ProductPage Messages
 *
 * This contains all the text for the ProductPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ProductPage';

export default defineMessages({
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews',
  },
  flashSaleInProgress: {
    id: `${scope}.flashSaleInProgress`,
    defaultMessage: 'Flash sale in progress',
  },
  flashSaleStartAt: {
    id: `${scope}.flashSaleStartAt`,
    defaultMessage: 'Flash sale  will start at',
  },
  notifyme: {
    id: `${scope}.notifyme`,
    defaultMessage: 'Remind me',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  favorited: {
    id: `${scope}.favorited`,
    defaultMessage: 'Favorited',
  },
  favoriteProduct: {
    id: `${scope}.favoriteProduct`,
    defaultMessage: 'Favorite',
  },
  relatedProducts: {
    id: `${scope}.relatedProducts`,
    defaultMessage: 'Related Products',
  },
  viewInterview: {
    id: `${scope}.viewInterview`,
    defaultMessage: 'more',
  },
  referenceIdColon: {
    id: `${scope}.referenceIdColon`,
    defaultMessage: 'Reference ID:',
  },
  netWeightColon: {
    id: `${scope}.netWeightColon`,
    defaultMessage: 'Net Weight:',
  },
  ingredients: {
    id: `${scope}.ingredients`,
    defaultMessage: 'Main Ingredients',
  },
  ingredientList: {
    id: `${scope}.ingredientList`,
    defaultMessage: 'Ingredients',
  },
  usage: {
    id: `${scope}.usage`,
    defaultMessage: 'Usage',
  },
  pcsInStock: {
    id: `${scope}.pcsInStock`,
    defaultMessage: '{num} pieces in stock',
  },
  specialControllerNotice: {
    id: `${scope}.specialControllerNotice`,
    defaultMessage: '*Exclusive set product',
  },
  specialControllerBtn: {
    id: `${scope}.specialControllerBtn`,
    defaultMessage: 'See how to qualify to buy',
  },
  buyExclusiveBundles: {
    id: `${scope}.buyExclusiveBundles`,
    defaultMessage: 'How to qualify for exclusive bundles',
  },
  buyExclusiveBundlesNotice: {
    id: `${scope}.buyExclusiveBundlesNotice`,
    defaultMessage:
      'To qualify for the exclusive bundle, you need to choose to purchase the following products',
  },
  iSee: {
    id: `${scope}.iSee`,
    defaultMessage: 'I See',
  },
  specialPackage: {
    id: `${scope}.specialPackage`,
    defaultMessage: 'Special Package',
  },
  specialPackageDesc: {
    id: `${scope}.specialPackageDesc`,
    defaultMessage:
      'This product is an exclusive set, you can click the button below to learn how to qualify for purchase.',
  },
  specialControllerCartNotice: {
    id: `${scope}.specialControllerCartNotice`,
    defaultMessage:
      'Please check the corresponding product first to obtain the purchase qualification of the exclusive package below',
  },
});
