import { FormattedMessage } from 'react-intl';
import appMessages from 'containers/App/messages';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';

const useCopyMeToClipBoard = () => {
  const notificationSnackbar = useNotificationSnackbar();

  return async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      notificationSnackbar(<FormattedMessage {...appMessages.copySuccess} />, 'success');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to copy!!!', err);
      notificationSnackbar(<FormattedMessage {...appMessages.copyFailed} />, 'warn');
    }
  };
};

export default useCopyMeToClipBoard;
