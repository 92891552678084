/**
 * Process WeChat Pay payment parameters before redirecting to native payment page
 * @param parameters
 * @param orderID
 */

class CustomError extends Error {
  constructor(message, extra) {
    super(message);
    this.name = this.constructor.name;
    this.extra = extra;
  }
}

export default function processWeChatPayParameters(parameters, orderID) {
  return new Promise((resolve, reject) => {
    try {
      if (parameters[0] === 'Ok' && parameters[2]) {
        const params = parameters[2];
        const prepayID = params.package.split('=');

        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/payment/payment?orderID=${orderID}&nonceStr=${params.nonceStr}&prepayID=${prepayID[1]}&paySign=${params.paySign}&signType=${params.signType}&timeStamp=${params.timeStamp}`,
        });

        resolve(true);
        return;
      }
    } catch (err) {
      console.log(err);
    }

    reject(new CustomError('支付参数获取错误', { parameters, orderID }));
  });
}
