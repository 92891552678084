import { createSlice } from '@reduxjs/toolkit';
import store from 'store';
import expirePlugin from 'store/plugins/expire';
import { DEFAULT_LOCALE } from '../../i18n';

store.addPlugin(expirePlugin);
export const STORE_LOCATION = 'location';
export const STORE_LANGUAGE = 'language';

export const appState = createSlice({
  name: 'app',
  initialState: {
    locale: DEFAULT_LOCALE,
    showNotificationDialog: true,
    showCouponDialog: true,
    stickyHeader: false,
    showMiniCart: false,
    category: 'all',
    showLoyaltyDialog: true,
    MPLoginVisible: false,
    headerChildrenNode: null,
    breadcrumbs: [],
  },
  reducers: {
    hideNotificationDialog: state => {
      state.showNotificationDialog = false;
    },
    hideCouponDialog: state => {
      state.showCouponDialog = false;
    },
    setStickyHeader: (state, action) => {
      state.stickyHeader = action.payload;
    },
    toggleMiniCart: (state, action) => {
      state.showMiniCart = action.payload;
    },
    setFilterCategory: (state, action) => {
      state.category = action.payload;
    },
    hideLoyaltyPointsDialog: state => {
      state.showLoyaltyDialog = false;
    },
    changeLanguage: (state, action) => {
      state.locale = action.payload;
    },
    toggleMPLoginVisible: (state, action) => {
      state.MPLoginVisible = action.payload;
    },
    setHeaderChildren: (state, action) => {
      state.headerChildrenNode = action.payload.children;
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload.breadcrumbs;
    },
  },
});

export const {
  changeLanguage,
  hideNotificationDialog,
  hideCouponDialog,
  setStickyHeader,
  toggleMiniCart,
  setFilterCategory,
  hideLoyaltyPointsDialog,
  toggleMPLoginVisible,
  setHeaderChildren,
  setBreadcrumbs,
} = appState.actions;

export const selectLanguage = state => state.app.locale;
export const selectShowNotificationDialog = state => state.app.showNotificationDialog;
export const selectShowCouponDialog = state => state.app.showCouponDialog;
export const selectStickyHeader = state => state.app.stickyHeader;
export const selectShowMiniCart = state => state.app.showMiniCart;
export const categoryFilterSelector = state => state.app.category;
export const selectShowLoyaltyDialog = state => state.app.showLoyaltyDialog;
export const selectMPLoginVisible = state => state.app.MPLoginVisible;
export const selectHeaderChildrenNode = state => state.app.headerChildrenNode;
export const selectBreadcrumbs = state => state.app.breadcrumbs;

export const selectGlobalApp = state => state.app;
export const selectRouterLocation = state => state?.router?.location;

export default appState.reducer;
